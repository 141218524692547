@font-face {
    font-family: 'Acumin Pro';
    src: url('AcuminPro-Semibold.eot');
    src: url('AcuminPro-Semibold.eot?#iefix') format('embedded-opentype'),
        url('AcuminPro-Semibold.woff2') format('woff2'),
        url('AcuminPro-Semibold.woff') format('woff'),
        url('AcuminPro-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

