@font-face {
  font-family: 'Avenir LT Pro';
  src: url('AvenirLTPro-Roman.eot');
  src: url('AvenirLTPro-Roman.eot?#iefix') format('embedded-opentype'),
    url('AvenirLTPro-Roman.woff2') format('woff2'), url('AvenirLTPro-Roman.woff') format('woff'),
    url('AvenirLTPro-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
