@font-face {
    font-family: 'Antro Vectra';
    src: url('AntroVectra.eot');
    src: url('AntroVectra.eot?#iefix') format('embedded-opentype'),
        url('AntroVectra.woff2') format('woff2'),
        url('AntroVectra.woff') format('woff'),
        url('AntroVectra.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

