/*! purgecss start ignore */
.slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide > div {
  width: 100%;
  overflow: visible !important;
}
.carousel.carousel-slider {
  position: static !important;
  overflow: visible !important;
}
.carousel .control-dots {
  position: absolute;
  bottom: 35px;
}

@media (max-width: 980px) {
  .hero .carousel .control-dots {
    display: none !important;
  }
}
/*! purgecss end ignore */
