@font-face {
    font-family: 'Lucida Grande';
    src: url('LucidaGrande.eot');
    src: url('LucidaGrande.eot?#iefix') format('embedded-opentype'),
        url('LucidaGrande.woff2') format('woff2'),
        url('LucidaGrande.woff') format('woff'),
        url('LucidaGrande.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

