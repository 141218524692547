html {
  width: 100%;
  @apply overflow-x-hidden;
}

/* purgecss start ignore */
#root {
  min-height: 100vh;
}

.noselect {
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.touch-none {
  touch-action: none;
}

.copyright {
  font-size: 0.8em;
}

@media (max-width: 768px) {
  .copyright {
    text-align: center;
  }
}
